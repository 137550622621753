import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useLazyDeleteComplaintsQuery,
  useLazyDeleteQueriesQuery,
  useLazyGetComplaintsQuery,
  useLazyGetQueriesQuery,
  useReplyComplaintsMutation,
  useReplyQueriesMutation,
} from "../../services/advertiser";
import { showError, showToast } from "../../constants";
import { isValidInput } from "../../utils/validations";
import WarnModal from "../../components/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { Pagination } from "../../components";
import useTranslation from "../../hooks/Translation";
import { SupportTicket } from "../../types/General";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerSupport = () => {
  const [value, setValue] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [query, setQuery] = useState<SupportTicket[]>([]);
  const [userQuery, setUserQuery] = useState<SupportTicket[]>([]);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [selectId, setSelectedId] = useState<string>("");
  const [error, setError] = useState<boolean>(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  function createData(
    Name: string,
    Email: string,
    Queries: string,
    Description: string
  ) {
    return { Name, Email, Queries, Description };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "abdulhussian67@gmail.com",
      "Lorem Ipsum",
      " Lorem Ipsum is simply dummy text.."
    ),
  ];

  const [getComplaints, { isLoading }] = useLazyGetComplaintsQuery();
  const [deleteById] = useLazyDeleteComplaintsQuery();
  const [reply, setReply] = useState<any>("");
  const [adminReply] = useReplyComplaintsMutation();
  const [queryReply] = useReplyQueriesMutation();
  const [deleteQuery] = useLazyDeleteQueriesQuery();
  const [getUserQueries] = useLazyGetQueriesQuery();

  const getQueries = async () => {
    try {
      const response = await getUserQueries({
        limit: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      });
      if (response?.data?.statusCode === 200) {
        setUserQuery(response?.data?.data?.queries || []);
        setTotalCount(response?.data?.data?.count);
      } else {
        setUserQuery([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getUserComplaints = async () => {
    try {
      const response: any = await getComplaints({
        search: debouncedSearchTerm.trim(),
        limit: 10,
        page: page,
      });
      if (response?.data?.statusCode === 200) {
        setQuery(response?.data?.data?.queries || []);
        setTotalCount(response?.data?.data?.count);
      } else {
        setQuery([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const totalPages = Math.ceil(totalCount / 10);
  const [open, setOpen] = React.useState(false);
  const sendReply = async () => {
    const body = {
      reply: reply,
      appKey: new Date().toISOString(),
    };

    try {
      const response = await adminReply({
        body,
        id: selectId,
      }).unwrap();

      if (response?.statusCode == 200) {
        getUserComplaints();
        showToast("Reply sent successFully");
        setOpen(false);
      }
    } catch (error: any) {
      showError(error?.data.message || "");
    }
  };
  const sendQueryReply = async () => {
    const body = {
      reply: reply,
      appKey: new Date().toISOString(),
    };

    try {
      const response = await queryReply({
        body,
        id: selectId,
      }).unwrap();

      if (response?.statusCode === 200) {
        getQueries();

        showToast("Reply sent successfully");
        setOpen(false);
      }
    } catch (error: any) {
      showError(error?.data.message || "");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setReply("");
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (value === 0) {
      getQueries();
    } else {
      getUserComplaints();
    }
  }, [debouncedSearchTerm, value, page, searchTerm]);

  const translations = useTranslation() as any;
  return (
    <div>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{translations.customer_support.customer_support || ""}</h1>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab
                  label={translations.customer_support.queries_received || ""}
                  {...a11yProps(0)}
                />
                <Tab
                  label={translations.customer_support.complaint_dispute || ""}
                  {...a11yProps(1)}
                />
              </Tabs>
              <CustomTabPanel value={value} index={0}>
                <Box className="cards_header">
                  <SearchBar
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onCross={() => setSearchTerm("")}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </Box>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {translations.table_heads.sno || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.queries || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.user_details || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.status || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.created_at || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.actions || ""}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {userQuery?.length ? (
                      userQuery?.map((item: any, index: any) => (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">
                              {(page - 1) * 10 + index + 1}
                            </TableCell>
                            <TableCell>
                              {item?.description?.length > 40
                                ? item?.description?.slice(0, 40) + "..."
                                : item?.description || ""}
                            </TableCell>
                            <TableCell>
                              <p>{item?.userName}</p>
                              <p> {item?.email}</p>
                            </TableCell>
                            <TableCell>
                              {item?.status === 0 ? "Pending" : "Resolved"}
                            </TableCell>
                            <TableCell>
                              {moment(item?.createdAt).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <IconButton
                                  onClick={() => {
                                    navigate(
                                      "/customer-support/details/" + item?._id,
                                      {
                                        state: { value: value },
                                      }
                                    );
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {item?.status === 0 ? (
                                  <IconButton
                                    onClick={() => {
                                      handleClickOpen();
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <ReplyIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    className="disabled_btn"
                                    disabled
                                    onClick={() => {
                                      handleClickOpen();
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <ReplyIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() => {
                                    setIsDelete(true);
                                    setSelectedId(item?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))
                    ) : (
                      <TableBody>
                        <TableCell
                          align="center"
                          colSpan={10}
                          sx={{ color: "#051140" }}
                        >
                          {translations.customer_support.not_found || ""}
                        </TableCell>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box className="cards_header">
                  <SearchBar
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onCross={() => setSearchTerm("")}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </Box>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {translations.table_heads.sno || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.queries || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.user_details || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.status || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.created_at || ""}
                        </TableCell>
                        <TableCell>
                          {translations.table_heads.actions || ""}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {query?.length ? (
                      query?.map((item: any, index: any) => (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">
                              {(page - 1) * 10 + index + 1}
                            </TableCell>
                            <TableCell>
                              {item?.description?.length > 40
                                ? item?.description?.slice(0, 40) + "..."
                                : item?.description || ""}
                            </TableCell>
                            <TableCell>
                              <p>{item?.userName}</p>
                              <p> {item?.email}</p>
                            </TableCell>
                            <TableCell>
                              {item?.status === 0 ? "Pending" : "Resolved"}
                            </TableCell>
                            <TableCell>
                              {moment(item?.createdAt).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <IconButton
                                  onClick={() => {
                                    navigate(
                                      "/customer-support/details/" + item?._id,
                                      {
                                        state: { value: value },
                                      }
                                    );
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {item?.status === 0 ? (
                                  <IconButton
                                    onClick={() => {
                                      handleClickOpen();
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <ReplyIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    className="disabled_btn"
                                    disabled
                                    onClick={() => {
                                      handleClickOpen();
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <ReplyIcon />
                                  </IconButton>
                                )}

                                <IconButton
                                  onClick={() => {
                                    setIsDelete(true);
                                    setSelectedId(item?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))
                    ) : (
                      <TableBody>
                        <TableCell
                          align="center"
                          colSpan={10}
                          sx={{ color: "#051140" }}
                        >
                          {translations.customer_support.not_found || ""}
                        </TableCell>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
            </Box>
          </Card>
        </div>

        <Dialog open={open} onClose={handleClose} className="revert_dialog">
          <DialogContent>
            <Typography className="dialog_title">
              {translations.customer_support.revert || ""}
            </Typography>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                autoFocus
                id="name"
                type="text"
                placeholder={translations.customer_support.revert || ""}
                fullWidth
                value={reply}
                multiline
                minRows={1}
                maxRows={4}
                onChange={(e) => {
                  setReply(e.target.value);

                  if (e.target.value === "") {
                    setError(e.target.value === "");
                  } else {
                    setError(false);
                  }
                }}
                required
                error={error}
                helperText={error ? translations.Globals.field_req || "" : ""}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
              {translations.Globals.cancel || ""}
              </Button>
              <Button
                className="btn btn_primary"
                onClick={() => {
                  if (!error) {
                    if (value == 0) {
                      sendQueryReply();
                    } else {
                      sendReply();
                    }
                  }
                }}
              >
                {translations.Globals.send || ""}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </MainContainer>
      <Pagination
        module={query}
        page={page}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <WarnModal
        setOpen={setIsDelete}
        open={isDelete}
        name={translations.Globals.compalaint || ""}
        handleDelete={() => {
          if (value == 0) {
            handleDelete(deleteQuery, selectId, getQueries);
            // setSelectedId("")
          } else {
            handleDelete(deleteById, selectId, getUserComplaints);
            // setSelectedId("")
          }
        }}
      />
    </div>
  );
};

export default CustomerSupport;
