import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import MainContainer from "../../layout/MainContainer";
import * as Yup from "yup";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  useLazyGetSubadminByIdQuery,
  usePostSubadminMutation,
  useUpdateSubAdminMutation,
} from "../../services/subadmin";
import { useFormik } from "formik";
import { UploadMedia } from "../../utils/mediaUpload";
import { Loader, showError, showToast } from "../../constants";
import { isString } from "../../utils/validations";
import PhoneInput from "react-phone-input-2";
import { GetSubadminResponse } from "../../types/General";
import useTranslation from "../../hooks/Translation";

interface DataType {
  _id?: string;
  label: string;
  isEdit: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
}

const AddSubAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translations=useTranslation()as any
  const [image, setImage] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [newData, setNewData] = useState<DataType[]>([] as any);
  const [subAdminData, setSubAdminData] = useState<GetSubadminResponse | null>(
    null
  );

  console.log(newData, "new");

  const [addSubAdmin, { isLoading }] = usePostSubadminMutation();
  const [subAdminById, subAdminByIdData] = useLazyGetSubadminByIdQuery();
  const [updateSubAdmin, updateSubadminData] = useUpdateSubAdminMutation();

  const [permissions, setPermissions] = useState<DataType[]>([
    {
      _id: "1",
      label: translations.sub_admin.dashboard||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    {
      _id: "2",
      label: translations.sub_admin.manage_users||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "3",
      label: translations.sub_admin.manage_advertisers||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "4",
      label: translations.sub_admin.manage_advertisements||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "5",
      label:translations.sub_admin.manage_content||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "6",
      label: translations.sub_admin.manage_journey||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "7",
      label: translations.sub_admin.challenge_categories||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "8",
      label: translations.sub_admin.subscription_plan||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "9",
      label: translations.sub_admin.manage_rewards||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "10",
      label: translations.sub_admin.customer_support||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "11",
      label: translations.sub_admin.manage_challenge||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "12",
      label: translations.sub_admin.manage_word||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "13",
      label: translations.sub_admin.manage_payment||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "14",
      label: translations.sub_admin.manage_notification||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "15",
      label: translations.sub_admin.report_analytics||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "16",
      label: translations.sub_admin.manage_cms||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "17",
      label: translations.sub_admin.manage_faq||"",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
  ]);

  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.label === key.label);
    if (type === "addEdit") {
      permissions[idx].isEdit = !key.isEdit;
      if (key.isEdit) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    } else if (type === "isview") {
      permissions[idx].isView = !key.isView;
    } else {
      permissions[idx].isDelete = !key.isDelete;
      if (key.isDelete) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    }
    setPermissions([...permissions]);
  };
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: subAdminData?.firstName || "",
      lastName: subAdminData?.lastName || "",
      email: subAdminData?.email || "",
      phone: subAdminData?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translations.Globals.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translations.Globals.valid_email
        ),
      firstName: Yup.string()
        .required(translations.Globals.fname_req)
        .min(3, translations.Globals.min_3)
        .max(30, translations.Globals.max_30),

      lastName: Yup.string()
        .min(2, translations.Globals.min_2)
        .max(30, translations.Globals.max_30),

      phone: Yup.string()
        .required(translations.Globals.phone_required)
        .min(6, translations.Globals.phone_6)
        .max(16,  translations.Globals.phone_16),

      // permissions: Yup.array().of(
      //   Yup.object().shape({
      //     isEdit: Yup.boolean(),
      //     isView: Yup.boolean(),
      //     isDelete: Yup.boolean(),
      //   })
      // ).min(1, 'At least one module must be selected'),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      permissions?.length > 0 &&
        permissions.map((element) => {
          const obj = {
            label: element?.label,
            isEdit: element.isEdit,
            isView: element.isView,
            isDelete: element.isDelete,
          };
          if (obj) {
            newData?.push(obj);
            setNewData([...newData]);
          }
        });

      const selectedModules = newData.filter((element) => {
        return (
          element.isView === true ||
          element.isEdit === true ||
          element.isDelete === true
        );
      });
      if (selectedModules?.length === 0) {
        showError(translations.Globals.allow_permission);
        return;
      }

      let body = {
        ...(values.email !== subAdminData?.email
          ? { email: values.email }
          : {}),
        ...(values.phone !== subAdminData?.phone ||
        phoneCode !== subAdminData?.countryCode
          ? { phone: values.phone }
          : {}),
        ...(values.phone !== subAdminData?.phone ||
        phoneCode !== subAdminData?.countryCode
          ? { countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode }
          : {}),

        // email: formik.values.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        // countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        // phone: formik.values.phone,
        image: image,
        permission: newData.filter((element) => {
          return (
            element.isView === true ||
            element.isEdit === true ||
            element.isDelete === true
          );
        }),
        appKey: new Date().toISOString(),
      };
      console.log(body, "body");

      if (id) {
        try {
          const response = await updateSubAdmin({
            sub_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translations.Globals.subadmin_update);
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addSubAdmin(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(translations.Globals.subadmin_create);
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const getSubAdminById = async (id: string) => {
    try {
      const response = await subAdminById({ sub_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setSubAdminData(response?.data);
        setPhoneCode(response?.data?.countryCode || "+91");
        setImage(response?.data?.image || "");
        const modifiedArr2 = permissions.map((item) => {
          const foundItem = response?.data?.permission?.find(
            (i: { label: string }) => i?.label === item.label
          );
          if (foundItem) {
            return {
              ...item,
              isView: foundItem.isView,
              isEdit: foundItem.isEdit,
              isDelete: foundItem.isDelete,
            };
          }
          return item;
        });
        setPermissions(modifiedArr2);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getSubAdminById(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={isLoading || subAdminByIdData?.isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">
              {id ? translations.sub_admin.edit||"" : translations.sub_admin.add||""}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              {translations.Globals.back||""}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">{translations.sub_admin.image||""}</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">{translations.sub_admin.fname||""}</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      placeholder={translations.sub_admin.fname||""}
                      inputProps={{ maxLength: 30 }}
                      id="firstName"
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">{translations.sub_admin.lname||""}</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      placeholder={translations.sub_admin.lname||""}
                      id="lastName"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">{translations.sub_admin.email||""}</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder={translations.sub_admin.email||""}
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translations.sub_admin.phone||""}
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      placeholder={translations.Globals.phone}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer className="table_container">
                      <Box className="heading"></Box>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="mn_hdng" align="center">
                              {translations.sub_admin.module||""}
                            </TableCell>
                            <TableCell className="mn_hdng" align="center">
                              {translations.sub_admin.add_edit||""}
                            </TableCell>
                            <TableCell className="mn_hdng" align="center">
                              {translations.sub_admin.view||""}
                            </TableCell>
                            <TableCell className="mn_hdng" align="center">
                              {translations.sub_admin.delete||""}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions.map((row, i) => (
                            <TableRow>
                              <TableCell className="mn_hdng">
                                {row.label}
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  {...label}
                                  onChange={() =>
                                    onSelectChange(row, "addEdit")
                                  }
                                  checked={row?.isEdit || false}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  {...label}
                                  onChange={() => onSelectChange(row, "isview")}
                                  checked={row?.isView || false}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  {...label}
                                  onChange={() => onSelectChange(row, "isdel")}
                                  checked={row?.isDelete || false}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.save||""}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
