import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Loader, showError } from "../../constants";
import {
  useLazyChangeSubAdminStatusQuery,
  useLazyDeleteSubAdminByIdQuery,
  useLazyGetAllSubAdminsQuery,
} from "../../services/subadmin";
import { GetSubadminResponse } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import useTranslation from "../../hooks/Translation";

const ManageSubAdmin = () => {
  const navigate = useNavigate();

  const [subadminList, setSubadminList] = useState<GetSubadminResponse[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [getSubadminList, { isLoading }] = useLazyGetAllSubAdminsQuery();
  const [changeSubAdminStatus] = useLazyChangeSubAdminStatusQuery();
  const [deleteSubadminById, deleteSubadminByIdData] =
    useLazyDeleteSubAdminByIdQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getSubadmins = async () => {
    try {
      const response = await getSubadminList({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubadminList(response?.data?.subAdmin || []);
        setTotalCount(response?.data?.count);
      } else {
        setSubadminList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };


  useEffect(() => {
    getSubadmins();
  }, [debouncedSearchTerm, page]);
  const translations=useTranslation()as any

  return (
    <MainContainer>
      <Loader isLoad={isLoading || deleteSubadminByIdData?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translations.sub_admin.manage_subAdmin||""}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
                {translations.sub_admin.add||""}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translations.table_heads.sno||""}</TableCell>
                  <TableCell>{translations.table_heads.name||""}</TableCell>
                  {/* <TableCell>Role</TableCell> */}
                  <TableCell>{translations.table_heads.email||""}</TableCell>
                  <TableCell>{translations.table_heads.phone||""}</TableCell>
                  <TableCell>{translations.table_heads.status||""}</TableCell>
                  <TableCell>{translations.table_heads.actions||""}</TableCell>
                </TableRow>
              </TableHead>
              {subadminList?.length ? (
                subadminList?.map((row, i) => {
                  return (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>
                          {row?.firstName} {row?.lastName}
                        </TableCell>
                        {/* <TableCell>Role name</TableCell> */}

                        <TableCell>{row?.email || ""}</TableCell>
                        <TableCell>
                          {row?.phone
                            ? (row?.countryCode.includes("+") ? "" : "+") +
                              row?.countryCode
                            : null}{" "}
                          {row?.phone || "---"}
                        </TableCell>
                        <TableCell>
                          <Switch

                            // size="small"
                            // checked={!row?.isBlocked}
                            onChange={() =>
                              updateStatus(
                                row?._id,
                                !row?.isBlocked,
                                changeSubAdminStatus,
                                setSubadminList,
                                subadminList
                                )
                              }
                            {...label} checked={!row.isBlocked} size="small" 
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() => navigate(`/manage-subAdmin/edit/${row?._id}`)}
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={10}>
                  {translations.sub_admin.not_found||""}
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={subadminList}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        <WarnModal
          setOpen={setOpen}
          open={open}
          name={translations.Globals.subadmin}
          handleDelete={() =>
            handleDelete(deleteSubadminById, selectedId, getSubadmins)
          }
        />
      </div>
    </MainContainer>
  );
};

export default ManageSubAdmin;
