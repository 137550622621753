import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyDeleteNotificationQuery, useLazyGetNotificationsQuery } from "../../services/advertiser";
import { Loader, getFromStorage, showError } from "../../constants";
import { isValidInput } from "../../utils/validations";
import moment from "moment";
import { Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import useTranslation from "../../hooks/Translation";
import { NotificationData } from "../../types/General";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";

const ManageNotifications = () => {
  const navigate = useNavigate();
  const userData=useAuth()
  const [notifications,setNotifications]=useState<NotificationData[]>()
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount,setTotalCount]=useState<number>(0)
  const [page,setPage]=useState<number>(1)
  const [open,setOpen]=useState<boolean>(false)
  const [selectId,setSelectId]=useState<string>("")
  const isArb=getFromStorage("LANGUAGE")
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getNotification,{isLoading}]=useLazyGetNotificationsQuery()

  function createData(name: string, title: string, message: string) {
    return { name, title, message };
  }
  const fetchNotifications=async()=>{
    try{
        const response=await getNotification({
          search:debouncedSearchTerm.trim(),
          limit:10,
          page:page
        }).unwrap()
        console.log(response,"ressdsdsdsdsds")
        if(response?.statusCode===200){
          setNotifications(response?.data?.notification)
          setTotalCount(response?.data?.notificationCount)
        }
    }catch(error:any){
      showError(error?.data?.message)
    }
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Notifications",
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const rows = [createData("Abdul Hussian", "Title", "Message")];

  useEffect(()=>{
    fetchNotifications()
  },[searchTerm,debouncedSearchTerm,page])
  useEffect(()=>{
    if(userData && userData?.permission?.length){
      checkPermission()
    }
  },[userData])

  const [deleteById]=useLazyDeleteNotificationQuery()

  let totalPages=Math.ceil(totalCount/10)
const translation=useTranslation()as any
  return (
    <MainContainer>
      <Loader isLoad={isLoading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.manage_notifications.manage_notifications||""}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isEdit || userData?.role==="ADMIN"?(

              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-notifications/add")}
              >
                {translation.manage_notifications.add||""}
              </Button>
              ):null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translation.table_heads.sno||""}</TableCell>
                  <TableCell align="center">{translation.table_heads.date||""}</TableCell>
                  <TableCell>{translation.table_heads.title||""}</TableCell>
                  <TableCell>{translation.table_heads.message||""}</TableCell>
                  <TableCell align="center">{translation.table_heads.delete||""}</TableCell>
                </TableRow>
              </TableHead>
              
              {notifications?.length?(
                  notifications?.map((item,index)=>(
                    <TableBody key ={item?._id}>
                      <TableRow>
                        
                          <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                          <TableCell align="center">{moment(item?.createdAt).format("DD/MM/YYYY")}</TableCell>
                          <TableCell>{isArb==="ar"?item?.title_ar:item?.title || "-"}</TableCell>
                          <TableCell>{item?.description || "-"}</TableCell>
                          <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isDelete || userData?.role==="ADMIN"?(

                              <IconButton
                                onClick={()=>{
                                  setOpen(true)
                                  setSelectId(item?._id)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ):null}

                            </Box>
                          </TableCell>
                        
                      </TableRow>
                    </TableBody>
                  ))
              ):(
                <TableBody>
                <TableCell
                  align="center"
                  colSpan={10}
                  sx={{ color: "#051140" }}
                >
                {translation.manage_notifications.not_found||""}
                </TableCell>
              </TableBody>
              )}

             
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
      module={notifications}
      totalPages={totalPages}
      onPageChange={onPageChange}
      page={page}
      />

      <WarnModal
      open={open}
      setOpen={setOpen}
      name={translation.Globals.notification}
      handleDelete={()=>{
        handleDelete(deleteById,selectId,fetchNotifications)
      }}
      />
    </MainContainer>
  );
};

export default ManageNotifications;
