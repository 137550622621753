import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useState, ChangeEvent, useEffect, useRef } from "react";
import {
  useAddNotificationsMutation,
  useLazyGetUsersNotificationPaginationQuery,
  useLazyGetUsersNotificationQuery,
} from "../../services/advertiser";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import { Loader, showError, showToast } from "../../constants";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useDebouncedCallback } from "use-debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBar from "../../components/SearchBar";
import useTranslation from "../../hooks/Translation";
import CircularProgress from "@mui/material/CircularProgress";
import { useLazyGetUsersQuery } from "../../services/user";
import { UserData } from "../../types/General";
const AddNotification = () => {
  const navigate = useNavigate();
  let number = 1;
  // const [loading,setLoading]=useState<boolean>(false)
  const [type, setType] = useState<string>("Both");
  const [checked, setChecked] = useState<boolean>(false);
  const label = { inputProps: { "aria-label": "controlled" } };
  const [page, setPage] = useState<number>(1);
  const [users, setUsers] = useState<UserData[]>([]);
  const [all, setAll] = useState<UserData[]>([]);
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [getUsers, { isLoading }] = useLazyGetUsersNotificationQuery();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  console.log(selectedUsers, "selectedUsers");
  const [save,setSave]=useState<boolean>(false)
  const translation = useTranslation() as any;
  const [addNotification] = useAddNotificationsMutation();
  const [getUsersPagination] = useLazyGetUsersQuery();
  const selectRef = useRef<any>(null);
  const [seacrhTerm, setSearchTerm] = useState<string>("");
  const [scrollBottom, setScrollBottom] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const fetchUsers = async () => {
    try {
      const response: any = await getUsersPagination({
        query: seacrhTerm.trim(),
        limit: 10,
        page: page,
      });

      if (response?.data?.statusCode === 200) {
        const newData = response?.data?.data?.user;
        console.log(response?.data?.data?.users);
        setUsers((prev: any) => [...prev, ...newData]);
        setLoading(false);
      }
    } catch (error: any) {}
  };
  const fetchUsersPagination = async () => {
    try {
      const response: any = await getUsersPagination({
        query: seacrhTerm.trim(),
        limit: 10,
        page: page,
      });

      if (response?.data?.statusCode === 200) {
        const newData = response?.data?.data?.user;

        // if (!searchInProgress) {
        //   // If no search term, update users state
        //   setUsers((prev: any) => [...prev, ...newData]);
        // } else {
        //   // If search term, update all state
        //   setAll([...newData]);

        // }

        // setUsers((prev:any)=>[...prev, ...newData])
        // // setAll((prev: any) => [...prev, ...newData]);
        setAll([...newData]);
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const handleSearch = useDebouncedCallback((value: any) => {
    setSearchInProgress(true);
    setLoading(true);
    setSearchTerm(value);
    if (value.trim() == "") {
      setAll([]);
      setSearchInProgress(false);
      setLoading(false);
      return;
    } else {
      fetchUsersPagination();
    }
  }, 500);

  const handleReceiverChange = (
    event: SelectChangeEvent<typeof selectedUsers>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(
      // Ensure the value is not null or undefined
      typeof value === "string" ? [value] : value
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleClose = () => {
    setAll([]);
  };
  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  // const handleScroll = (event: any) => {
  //   console.log("hello from inside");
  //   if (!selectRef.current) {
  //     console.log("hello from inside");
  //     return;
  //   }
  //   const target = selectRef.current;
  //   console.log(target, "target");

  //   const bottom =
  //     target.scrollHeight - target.scrollTop <= target.clientHeight + 1;
  //   if (bottom) {
  //     console.log("Scrollbar hits bottom");
  //     // setPage(page=>page+1)

  //     setLoading(true);
  //     // useDebounce(()=>{
  //     //   fetchUsersPagination()
  //     // },1000)

  //     setTimeout(() => {
  //       fetchUsersPagination();
  //     }, 2000);
  //   }
  // };

  const handleScroll = useDebouncedCallback(() => {
    const element = selectRef.current;
    if (element) {
      const bottom =
        Math.ceil(element.scrollTop + element.clientHeight) >=
        element.scrollHeight;
      if (bottom && !loading) {
        setLoading(true);
        setPage((prev) => prev + 1);
        // fetchUsersPagination();
      }
    }
  }, 1000);
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      description_ar: "",
      title_ar: "",
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .required(translation.Globals.field_req)
        .min(2, translation.Globals.min_2),
      title_ar: Yup.string()
      .required(translation.Globals.field_req)
      .min(2, translation.Globals.min_2),
      description: Yup.string()
      .required(translation.Globals.field_req)
      .min(2, translation.Globals.min_2),
      description_ar: Yup.string()
      .required(translation.Globals.field_req)
      .min(2, translation.Globals.min_2),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      setSave(true)
      if (selectedUsers.length === 0 && !checked) {
        showError(translation.Globals.select_not);
        return;
      }
      const body = {
        title: values.title,
        title_ar: values.title_ar,
        description: values.description,
        description_ar: values.description_ar,
        type: "USER",
        userId: selectedUsers,
        notificationType:"NOTIFICATION",
        appKey: new Date().toISOString(),
      };
      console.log(body, "body");

      const Allbody = {
        title: values.title,
        title_ar: values.title_ar,
        description: values.description,
        description_ar: values.description_ar,
        type: "ALL",

        notificationType:"NOTIFICATION",
        
        appKey: new Date().toISOString(),
      };

      try {
        const response: any = await addNotification({
          body: checked ? Allbody : body,
        }).unwrap();
        console.log(response, "ress");
        if (response?.statusCode === 200) {
          showToast(translation.Globals.notification_send);
          navigate("/manage-notifications");
          // setLoading(false)
          setSave(false)
        }
      } catch (error: any) {
        showError(error?.data?.message);
        setSave(false)
      }
    },
  });

  useEffect(() => {
    fetchUsers();
  }, [page]);
  // useEffect(()=>{
  //   handleSearch(seacrhTerm)
  // },[seacrhTerm])

 
  return (
    <>
      <MainContainer>
        {/* <Loader isLoad={loading}/> */}
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">
              {translation.manage_notifications.add || ""}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              {translation.Globals.back || ""}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.manage_notifications
                        .notification_title_eng || ""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder=  {translation.manage_notifications
                        .notification_title_eng || ""}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{
                        maxLength: 20,
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        <h6 className="err_msg">
                          {formik.touched.title && formik.errors.title}
                        </h6>
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.manage_notifications
                        .notification_title_arb || ""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title_ar"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.manage_notifications
                        .notification_title_arb || ""}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{
                        maxLength: 20,
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        <h6 className="err_msg">
                          {formik.touched.title_ar && formik.errors.title_ar}
                        </h6>
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.manage_notifications.notification_msg_eng ||
                        ""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="description"
                      variant="outlined"
                      fullWidth
                      placeholder=    {translation.manage_notifications.notification_msg_eng ||
                        ""}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        <h6 className="err_msg">
                          {formik.touched.description &&
                            formik.errors.description}
                        </h6>
                      }
                      inputProps={{
                        maxLength: 40,
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.manage_notifications.notification_msg_arb ||
                        ""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="description_ar"
                      variant="outlined"
                      fullWidth
                      placeholder= {translation.manage_notifications.notification_msg_arb ||
                        ""}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        <h6 className="err_msg">
                          {formik.touched.description_ar &&
                            formik.errors.description_ar}
                        </h6>
                      }
                      inputProps={{
                        maxLength: 40,
                      }}
                    ></TextField>
                  </Grid>

                  {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.manage_notifications.receiver || ""}
                    </Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        // onScroll={handleScroll}
                        fullWidth
                        className="user_lst"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedUsers}
                        sx={{ overflowY: "auto" }}
                        multiple
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        MenuProps={{
                          sx: { maxHeight: "300px" },
                          PaperProps: {
                            ref: selectRef,
                            onScroll: handleScroll,
                          },
                        }}
                        disabled={checked}
                        displayEmpty
                        onChange={handleReceiverChange}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select</span>;
                          }
                          const selectedUserNames = selected.map((userId) => {
                            const userFound = users.find(
                              (user: any) => user._id === userId
                            );
                            return userFound ? `${userFound.userName}` : "";
                          });
                          return selectedUserNames.join(", ");
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>

                        {all?.map((item: any, index: any) => (
                          <MenuItem value={item?._id}>
                            {item?.userName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  {/* for try purpose only */}
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                     {translation.Globals.receiver}
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        className="users_autocomplete"
                        disabled={checked}
                        options={
                          seacrhTerm
                            ? all
                                ?.map((option: any) =>
                                  option?.userName ? option.userName : null
                                )
                                .filter((username) => username !== null)
                            : users
                                ?.map((option: any) =>
                                  option?.userName ? option.userName : null
                                )
                                .filter((username) => username !== null)
                        }
                        multiple
                        loading={loading}
                        autoComplete
                        open={open}
                        disableCloseOnSelect
                        limitTags={4}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        onInputChange={(event, newInputValue) => {
                          setSearchTerm(newInputValue);
                          handleSearch(newInputValue);
                        }}
                        ListboxProps={{
                          ref: selectRef,
                          onScroll: handleScroll,
                          sx: {
                            maxHeight: 200,
                            overflowY: "auto",
                          },
                        }}
                        
                        onChange={(event, value) => {
                          console.log(value, "values");
                            
                          const selectedUserNames = value?.map((username) => {
                            const userFound = users.find(
                              (user: any) => user?.userName === username
                            );

                            return userFound ? `${userFound._id}` : "";
                          });
                          console.log(selectedUserNames, "ll");
                          setSelectedUsers([...selectedUserNames]);
                        }}
                        noOptionsText={"No users found"}
                        loadingText={"loading"}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Select User"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    // <CircularProgress color="inherit" size={20} />
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />
                  {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.manage_notifications.type || ""}
                    </Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        // className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleChangeType}
                      >
                        <MenuItem value="" disabled>
                          {translation.manage_notifications.select || ""}
                        </MenuItem>
                        <MenuItem value="Both">
                          {translation.manage_notifications.both || ""}
                        </MenuItem>
                        <MenuItem value="Email">
                          {translation.manage_notifications.email || ""}
                        </MenuItem>
                        <MenuItem value="Notification">
                          {translation.manage_notifications.notification || ""}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...label}
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label={translation.manage_notifications.send_all || ""}
                    />
                  </Grid>
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    disabled={save}
                  >
                    {translation.Globals.save || ""}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
