import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useLazyChangeCategoryStatusQuery,
  useLazyDeleteCategoryByIdQuery,
  useLazyGetAllCategoriesQuery,
} from "../../services/categories";
import { CategoryResponse } from "../../types/General";
import { Loader, getFromStorage, showError } from "../../constants";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import useTranslation from "../../hooks/Translation";

const ManageCategories = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const isArb=getFromStorage("LANGUAGE")
  
  const [category, setCategory] = useState<CategoryResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getCategories, { isLoading }] = useLazyGetAllCategoriesQuery();
  const [updateCategory] = useLazyChangeCategoryStatusQuery();
  const [deleteCategory] = useLazyDeleteCategoryByIdQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Challenge Categories",
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const getCategory = async () => {
    try {
      const response = await getCategories({
        query: debouncedSearchTerm.trim(),
        page: page,
        limit: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data?.categories || []);
        setTotalCount(response?.data?.count);
      } else {
        setCategory([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Travel"), createData("Health")];

  useEffect(() => {
    getCategory();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  const translations=useTranslation()as any
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translations.manage_categories.manage_categories||""}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-categories/add")}
                  >
                    {translations.manage_categories.add_category||""}
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{translations.table_heads.sno||""}</TableCell>
                    <TableCell>{translations.table_heads.image||""}</TableCell>
                    <TableCell>{translations.table_heads.category_name||""}</TableCell>
                    <TableCell>{translations.table_heads.status||""}</TableCell>
                    <TableCell>{translations.table_heads.actions||""}</TableCell>
                  </TableRow>
                </TableHead>
                {category?.length ? (
                  category?.map((row, i) => {
                    return (
                      <TableBody key={row?._id}>
                        <TableRow>
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell>
                            <figure className="user_img">
                              <img src={row?.image} alt="" />
                            </figure>
                          </TableCell>
                          <TableCell>{isArb==="ar"?row?.name_ar:row?.name}</TableCell>
                          <TableCell>
                            <Switch
                              {...label}
                              defaultChecked
                              size="small"
                              checked={!row?.isBlocked}
                              onChange={() =>
                                updateStatus(
                                  row?._id,
                                  !row?.isBlocked,
                                  updateCategory,
                                  setCategory,
                                  category,
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              {/* <IconButton>
                                <VisibilityIcon
                                  onClick={() =>
                                    navigate(
                                      `/manage-categories/details/${row?._id}`
                                    )
                                  }
                                />
                              </IconButton> */}
                              {hidePermission?.isEdit ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-categories/edit/${row?._id}`,
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              ) : null}
                              {hidePermission?.isDelete ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      {translations.manage_categories.not_found||""}
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
        <Pagination
          module={category}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name= {translations.manage_journey.category||""}
        handleDelete={() =>
          handleDelete(deleteCategory, selectedId, getCategory)
        }
      />
    </MainContainer>
  );
};

export default ManageCategories;
